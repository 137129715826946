<template>
  <div class="SignIn w-screen h-screen inset-0 text-white relative">
    <div class="Signup__container h-screen ">
      <div class="absolute inset-0 z-20 px-8">
        <div class="mt-25 flex items-center justify-center flex-col">
          <Logo></Logo>
          <h1
              class="text-primary mt-2"
              style="
                font-size: 24px;
                font-family: Futura;
                letter-spacing: 0.1em;
              "
          >
            PLATFORM<span class="font-bold">MANAGER</span>
          </h1>
        </div>

        <div class="flex justify-center items-center signin-login-content">
          <div>
            <div class="SignIn__formWrap text-black">
              <form @submit.prevent="handleLogin" class="SignIn__form">
                <div>
                  <label for="email" class="text-sm mb-2 block">관리자 아이디</label>
                  <input
                    id="email"
                    v-model="email"
                    class="relative h-10 border inline-flex px-4 text-sm border-black w-full text-black admin-input"
                    @blur="checkIsEmailValid"
                  />
                  <div v-if="isEmailError" class="text-error text-sm mt-1">
                    잘못된 이메일주소입니다.
                  </div>
                </div>

                <div class="mt-4">
                  <label for="password" class="text-sm  mb-2 block">비밀번호</label>
                  <input
                    id="password"
                    v-model="password"
                    class="relative h-10 border inline-flex px-4 text-sm border-black w-full text-black admin-input"
                    type="password"
                  />
                </div>
                <div class="mt-5">
                  <!-- :checked="isKeepId" -->
                  <CheckboxDark
                    className="Signup__checkbox"
                    label="아이디 저장"
                    name="keepEmail"
                    v-model:checked="isKeepId"
                  ></CheckboxDark>
                </div>
                <button
                    class="
                      w-60
                      h-16
                      border border-black
                      shadow
                      flex
                      items-center
                      justify-center
                      mt-20
                      mx-auto
                      admin-button
                    "
                    type="submit"
                >
                  <IconBase>
                    <CheckCircleIcon></CheckCircleIcon>
                  </IconBase>
                  <span class="ml-2">로그인</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Logo from './Logo.vue';
import CheckboxDark from './CheckboxDark/index.vue';
import partnerAPI from '@/service/partnerAPI';
import axios from 'axios';
import { useRouter } from 'vue-router';
import storageKey from '@/constants/storageKey';
import localStorage from '@/utils/localStorage';
import isValidEmail from '@/utils/isValidEmail';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';

/**
 * 로그인
 */
export default defineComponent({
  name: 'SignIn',
  components: {
    Logo,
    CheckboxDark,
    IconBase,
    CheckCircleIcon
  },
  setup() {
    const classes = {
      formInputWrap: 'h-15 border-b border-gray-400 flex items-center',
      formInput: 'flex-1 bg-transparent focus:outline-none px-4',
      commonButton:
        'h-10 w-full text-sm flex justify-center items-center text-center border border-gray-700',
    };

    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const isPasswordVisible = ref(false);
    const isKeepId = ref();
    const isEmailError = ref(false);

    const checkIsEmailValid = () => {
      console.log('email.value', email.value);

      isEmailError.value = !isValidEmail(email.value);
      console.log('check', isEmailError.value);
    };

    const handleLogin = () => {
      checkIsEmailValid();

      partnerAPI.adminAuth
        .loginUsingPOST({
          authParamDTO: {
            email: email.value,
            password: password.value,
          },
        })
        .then(({ data }) => {
          const authToken = `Bearer ${(data as any).data.token}`;

          axios.defaults.headers.common['Authorization'] = authToken;
          localStorage.set(storageKey.AUTH_TOKEN, authToken);
          localStorage.set(storageKey.PARTNER_INFO, (data as any).data);

          if (isKeepId.value) {
            localStorage.set(storageKey.SIGN_IN_EMAIL, email.value);
          }

          router.push('/app');
        })
        .catch(() => {
          alert('로그인에 실패했습니다.');
        });
    };

    const togglePasswordVisible = () => {
      isPasswordVisible.value = !isPasswordVisible.value;
    };

    return {
      handleLogin,
      classes,
      isPasswordVisible,
      email,
      password,
      togglePasswordVisible,
      isKeepId,
      isEmailError,
      checkIsEmailValid,
    };
  },
});
</script>

<style lang="scss" scoped>
.SignIn__blackBG {
  background: #181818;
}

.SignIn__imageBG {
  width: 70vh;
  height: 100vh;
  background-image: url('/images/signin_bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #181818;
}

.Signup__container {
  position: relative;
  width: 1664px;
  margin-left: auto;
  margin-right: auto;
}

.SignIn__formWrap {
  width: 400px;
}

.SignIn__form {
}

.Signup__checkbox {
  color: #999;
}
.signin-login-content {
  height: calc(100% - 6.25rem - 68px);
}
.admin-input {
  &:focus {
    outline: none;
    border: 1px solid #49CDDA;
  }
}
.admin-button {
  &:hover {
    background: #E3FAFE;
  }
  &:focus {
    background: #171717;
    color: #ffffff;
  }
}
</style>
