<template>
  <div :class="[className, 'inline-flex items-center']">
    <input
      :id="name"
      type="checkbox"
      class="Checkbox"
      :class="[
        classes?.input,
        {
          disabled: 'border-disabled',
          isChecked: checked,
          isDisabled: disabled,
        },
      ]"
      :value="value"
      @change="$emit('update:checked', !checked)"
      :disabled="disabled"
    />
    <label
      v-if="name"
      :for="name"
      :class="['ml-4 cursor-pointer text-sm', disabled && 'text-disabled']"
      >{{ label }}</label
    >
    <span
      v-if="description"
      :class="['text-xs ml-4', disabled && 'text-disabled']"
      >{{ description }}</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CheckboxDark',
  emits: ['update:checked'],
  props: {
    classes: {
      type: Object as PropType<{ input: string }>,
      toBeRequired: false,
    },
    checked: Boolean,
    name: {
      type: String,
      isRequired: false,
    },
    disabled: {
      type: Boolean,
      isRequired: false,
    },
    label: {
      type: String,
      isRequired: false,
    },
    description: {
      type: String,
      isRequired: false,
    },
    className: {
      type: String,
      isRequired: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.Checkbox {
  display: block;
  border-width: 1px;
  border-color: #666666;
  width: 20px;
  height: 20px;
  appearance: none;
  &.isChecked {
    background: url('./ico_check.svg') no-repeat center;
  }
  &.isDisabled {
    border-color: #d1d5db;
  }
}
</style>
