<template>
  <svg
    width='152'
    height='24'
    viewBox='0 0 152 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2 21.833V2.23145H4.94076C6.25058 2.23145 7.29422 2.33301 8.07166 2.53613C8.85755 2.73926 9.52092 3.08203 10.0617 3.56445C10.611 4.0638 11.0462 4.68587 11.3673 5.43066C11.6969 6.18392 11.8617 6.94564 11.8617 7.71582C11.8617 9.12077 11.3251 10.3099 10.2519 11.2832C11.2913 11.6387 12.111 12.2607 12.711 13.1494C13.3194 14.0296 13.6236 15.0537 13.6236 16.2217C13.6236 17.7536 13.0828 19.0485 12.0011 20.1064C11.3504 20.7581 10.6195 21.2109 9.80823 21.4648C8.92093 21.7103 7.8097 21.833 6.47452 21.833H2ZM4.95344 10.6484H5.87876C6.97732 10.6484 7.78012 10.4072 8.28715 9.9248C8.80263 9.43392 9.06036 8.71452 9.06036 7.7666C9.06036 6.84408 8.7984 6.14583 8.27447 5.67188C7.75054 5.18945 6.99 4.94824 5.99285 4.94824H4.95344V10.6484ZM4.95344 19.0527H6.77874C8.11391 19.0527 9.09417 18.7904 9.7195 18.2656C10.3786 17.6986 10.7082 16.9792 10.7082 16.1074C10.7082 15.2611 10.3913 14.5501 9.75753 13.9746C9.14064 13.4076 8.03786 13.124 6.44917 13.124H4.95344V19.0527Z'
      fill='#000000'
    />
    <path
      d='M22.9124 2.23145V19.0527H28.6671V21.833H19.9589V2.23145H22.9124Z'
      fill='#000000'
    />
    <path
      d='M46.6514 17.0723H38.2728L36.0926 21.833H32.911L42.5952 1L51.9372 21.833H48.7049L46.6514 17.0723ZM45.4472 14.292L42.5445 7.62695L39.5023 14.292H45.4472Z'
      fill='#000000'
    />
    <path
      d='M69.5426 3.28516V6.78906C67.8356 5.35872 66.0694 4.64355 64.2441 4.64355C62.2329 4.64355 60.5386 5.36719 59.1612 6.81445C57.7753 8.25326 57.0824 10.0137 57.0824 12.0957C57.0824 14.1523 57.7753 15.8874 59.1612 17.3008C60.5471 18.7142 62.2456 19.4209 64.2568 19.4209C65.2962 19.4209 66.1793 19.2516 66.906 18.9131C67.3117 18.7438 67.7299 18.5153 68.1609 18.2275C68.6003 17.9398 69.0609 17.5928 69.5426 17.1865V20.7539C67.8525 21.7103 66.0779 22.1885 64.2188 22.1885C61.4217 22.1885 59.0344 21.2109 57.057 19.2559C55.0881 17.2839 54.1036 14.9056 54.1036 12.1211C54.1036 9.62435 54.9275 7.39844 56.5753 5.44336C58.6034 3.04818 61.2273 1.85059 64.4469 1.85059C66.2046 1.85059 67.9032 2.32878 69.5426 3.28516Z'
      fill='#000000'
    />
    <path
      d='M79.3891 10.0518L87.0072 2.23145H90.9493L82.1017 11.1055L91 21.833H87.0452L79.9975 13.1113L79.3891 13.7207V21.833H76.4356V2.23145H79.3891V10.0518Z'
      fill='#000000'
    />
    <path
      d='M146.296 6.54549V21.8335H141.2V6.54549H137.014V2.22949H150.482V6.54549H146.296Z'
      fill='#000000'
    />
    <path
      d='M105.194 2.22949V17.5175H111.304V21.8335H100.098V2.22949H105.194Z'
      fill='#000000'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M119.651 12C119.651 14.7614 121.89 17 124.651 17C127.413 17 129.651 14.7614 129.651 12C129.651 9.23858 127.413 7 124.651 7C121.89 7 119.651 9.23858 119.651 12ZM124.651 2C119.129 2 114.651 6.47715 114.651 12C114.651 17.5228 119.129 22 124.651 22C130.174 22 134.651 17.5228 134.651 12C134.651 6.47715 130.174 2 124.651 2Z'
      fill='#000000'
    />
  </svg>
</template>;
